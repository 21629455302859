<template>
  <discover searchType="4" />
</template>

<script>
import Discover from '@/components/Discover'

export default {
  name: 'OnlineUsers',
  components: {
    Discover,
  }
}
</script>
