<template>
  <div>
    <b-overlay :show="loading" no-center :opacity="0.3" variant="light" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <div class="filter-icon" @click="filterShow = !filterShow">
        {{ $t('Filter') }}
        <b-img :src="iconFilter" class="ml-1"/>
      </div>
      <transition mode="out-in">
        <b-row v-if="filterShow" class="user-filters mb-4">
          <b-col class="filter-column" md="4" sm="12">
            <div class="filter-field">
              <p class="filter-field-title">
                {{ $t('Looking for Gender') }}
              </p>
              <b-row>
                <b-col class="text-center cursor-pointer" sm="4" cols="4" @click="filter.gender = 'both'">
                  <b-img thumbnail rounded="circle" :src="imageGenderBoth" :alt="$t('Both')"
                         :class="{active: filter.gender === 'both'}" />
                  <br>
                  {{ $t('Both') }}
                </b-col>
                <b-col class="text-center cursor-pointer" sm="4" cols="4" @click="filter.gender = 'female'">
                  <b-img thumbnail rounded="circle" :src="imageGenderFemale" :alt="$t('Female')"
                         :class="{active: filter.gender === 'female'}" />
                  <br>
                  {{ $t('Female') }}
                </b-col>
                <b-col class="text-center cursor-pointer" sm="4" cols="4" @click="filter.gender = 'male'">
                  <b-img thumbnail rounded="circle" :src="imageGenderMale" :alt="$t('Male')"
                         :class="{active: filter.gender === 'male'}"/>
                  <br>
                  {{ $t('Male') }}
                </b-col>
              </b-row>
            </div>
            <div class="vr"></div>
          </b-col>
          <b-col class="filter-column" md="4" sm="12">
            <div class="filter-field">
              <p class="filter-field-title">
                {{ $t('Age Range') }}
              </p>
              <b-row>
                <b-col>
                  <vue-slider
                      v-model="filter.ageRange"
                      v-bind="rangeOptions"
                  />
                  <p class="age-range-text mt-1">
                    {{ filter.ageRange.join(' - ') }}
                  </p>
                </b-col>
              </b-row>
            </div>
            <div class="vr"></div>
          </b-col>
          <b-col class="filter-column" md="4" sm="12">
            <div class="filter-field">
              <p class="filter-field-title">
                {{ $t('Country') }}
              </p>
              <b-row>
                <b-col>
                  <v-select
                      v-model="filter.country"
                      :clearable="false"
                      :filter="countrySearch"
                      :reduce="country => country.code"
                      :options="countries"
                  >
                    <template v-slot:option="option">
                    <span class="mr-1">
                      {{ option.emoji }}
                    </span>
                      {{ $t(option.name) }}
                    </template>
                    <template v-slot:selected-option="option">
                    <span class="mr-1">
                      {{ option.emoji }}
                    </span>
                      {{ $t(option.name) }}
                    </template>
                  </v-select>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </transition>
      <b-row v-if="!users || users.length < 1">
        <!--        <user-card v-for="i in 6" :key="i + 100" :user="sampleUser" @setLoading="setLoading($event)"/>-->
        <!--        <b-col v-for="i in 12" xl="2" lg="3" md="4" sm="6" xs="12" :key="i" class="mb-2">-->
        <!--          <b-skeleton-img height="320"/>-->
        <!--        </b-col>-->
        <discover-no-user />
      </b-row>
      <b-row v-else>
        <user-card v-for="user in users" :key="user.id" :user="user" @setLoading="setLoading($event)"/>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BModal, BOverlay, BSkeleton, BSkeletonImg, BImg } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import UserCard from '@/components/UserCard'
import DiscoverNoUser from '@/components/DiscoverNoUser'
import VueSlider from 'vue-slider-component'
import countries from '@/data/countries.json'
import vSelect from 'vue-select'
import _ from 'lodash'
import { throwDefaultError, showNotify } from '@/helpers/helpers'

// import { getUser } from '@/auth/utils'

export default {
  name: 'discover',
  props: {
    camera: {
      type: Boolean,
      default: false
    },
    searchType: {
      type: String,
      default: '0' // 0: default, 1: new users
    },
  },
  components: {
    DiscoverNoUser,
    UserCard,
    BButton,
    BCol,
    BImg,
    BModal,
    BOverlay,
    BRow,
    BSkeleton,
    BSkeletonImg,

    vSelect,
    VueSlider
  },
  data () {
    return {
      countries: _.sortBy(countries, 'name').map(c => ({ ...c, name: this.$t(c.name) })),
      favoriteUsers: [],
      filter: null,
      filterChangedSetTimeout: false,
      filterChangedSetTimeoutTime: 1300,
      filterShow: false,
      iconFilter: require('@/assets/images/icons/filter.svg'),
      imageGenderBoth: require('@/assets/images/genders/both.png'),
      imageGenderFemale: require('@/assets/images/genders/female.png'),
      imageGenderMale: require('@/assets/images/genders/male.png'),
      initialFilter: {
        ageRange: [25, 40],
        ageFrom: null,
        ageTo: null,
        country: 'TR',
        gender: 'female',
      },
      loading: true,
      loadingImage: require('@/assets/images/loading/loading-transparent.gif'),
      rangeOptions: {
        min: 18,
        max: 100,
        enableCross: false,
        dotOptions: {
          style: {
            backgroundColor: '#F9447A',
            border: '2px solid #fff',
            boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.35)'
          }
        },
      },
      sampleUser: {
        id: '30310f2612ef4092bb841a9a4d541349',
        userName: 'ozkanozdemir',
        profile: {
          onlineStatus: 'online',
          image: 'https://picsum.photos/200',
          country: 'TR',
          year: 1987
        },
        interests: [
          {
            "code": 1,
            "name": "Spor"
          },
          {
            "code": 2,
            "name": "Müzik"
          },
          {
            "code": 3,
            "name": "Dans"
          }
        ]
      },
      searchQuery: '',
      scrollOffsetForLoadMore: 100,
      users: [],
      usersLimit: 100,
      usersOffset: 0,
      usersAllLoaded: false,
    }
  },
  watch: {
    filter: {
      handler (value) {
        if (this.filterChangedSetTimeout) {
          clearTimeout(this.filterChangedSetTimeout)
        }
        this.filterChangedSetTimeout = setTimeout(() => {
          this.$store.dispatch('app/setSearchFilter', value)  // set filter to store
          this.$store.dispatch('search/updateFilter', value)  // set filter to store
          // this.updateSearchCriteria({
          //   ageFrom: this.filter.ageRange[0],
          //   ageTo: this.filter.ageRange[1],
          //   country: this.filter.country,
          //   gender: this.filter.gender,
          // })
          this.getUsers()
        }, this.filterChangedSetTimeoutTime)
      }, deep: true
    },
    inStoreSearchFilter: {
      handler (value) {
        // this.filter = value
      }, deep: true
    },
    $route: {
      handler (value) {
        const userName = value.query.userName
        if (userName) {
          this.searchQuery = userName
          this.getUsers()
        }

        if (typeof value.params.filter !== 'undefined') {
          this.filter = _.cloneDeep(value.params.filter)
        }
      }, deep: true
    }
  },
  computed: {
    inStoreSearchFilter() {
      return this.$store.getters['app/searchFilter']
    }
  },
  created () {
    // Scroll'u dinle
    window.addEventListener('scroll', this.handleScroll)

    // this.getFavoriteUsers()

    // Params ile gelen filter ve searchQuery'i al
    this.searchQuery = this.$route.query?.userName || ''
    if (typeof this.$route.params.filter !== 'undefined') {
      this.filter = _.cloneDeep(this.$route.params.filter)
    } else {
      // this.filter = this.$store.getters['app/searchFilter']  // get filter from store
      // this.filter = _.cloneDeep(this.$store.getters['search/filter'])  // get filter from store
      if (!this.filter) {
        this.getMe()
      }
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  updated () {
    this.$nextTick(() => {
      // if (this.$route.params?.filter) {
      //   this.filter = { ...this.$route.params.filter }
      // }
    })
  },
  methods: {
    countrySearch(options, search) {
      return options.filter(country => {
        return country.name.toLowerCase().includes(search.toLowerCase())
            || country.code.toLowerCase().includes(search.toLowerCase())
      })
    },
    getFavoriteUsers () {
      useJwt.favoriteUsers(0, 100)
          .then(response => {
            try {
              this.favoriteUsers = response.data.body.users
            } catch (e) {

            }
          })
          .catch(error => {})
    },
    getMe () {
      useJwt.getMe()
          .then(response => {
            try {
              const searchCriteria = response.data.body.searchCriteria

              const ageRange = [
                searchCriteria.ageFrom || this.initialFilter.ageRange[0],
                searchCriteria.ageTo || this.initialFilter.ageRange[1]
              ]

              this.filter = {
                ageRange: ageRange,
                ageFrom: searchCriteria.ageFrom,
                ageTo: searchCriteria.ageTo,
                country: searchCriteria.country || 'TR',
                gender: searchCriteria.gender,
              }
              this.$store.dispatch('app/setSearchFilter', this.filter)  // set filter to store
              this.$store.dispatch('search/updateFilter', this.filter)  // set filter to store

              // this.updateSearchCriteria({
              //   ageFrom: this.filter.ageRange[0],
              //   ageTo: this.filter.ageRange[1],
              //   country: this.filter.country,
              //   gender: this.filter.gender,
              // })
            } catch (e) {

            }
          })
          .catch(error => {})
          .finally(() => {
            // this.getUsers()
          })
    },
    getUsers (clear = true) {
      if (clear) {
        this.users = []
        this.usersOffset = 0
        this.usersAllLoaded = false
        this.$store.dispatch('app/setSearchBar', false)  // set filter to store
      }
      this.loading = true
      const _filter = {
        id: this.$store.getters['auth/userId'],
        searchType: this.searchType,
      }
      if (typeof this.searchQuery === 'string' && this.searchQuery !== '') {
        _filter['userName'] = this.searchQuery
      } else {
        _filter['ageFrom'] = this.filter.ageRange[0]
        _filter['ageTo'] = this.filter.ageRange[1]
        _filter['country'] = this.filter.country
        _filter['gender'] = this.filter.gender === 'both' ? null : this.filter.gender
      }
      if (!!this.camera) {
        _filter['camera'] = true
      }
      useJwt.loggedUsers(this.usersOffset, this.usersLimit, _filter)
          .then(response => {
            try {
              if (clear) {
                this.users = response.data.body.users
                if (this.users.length <= 0) {
                  showNotify(this.$toast, this.$t('User not found'), 'info')
                }
              } else {
                this.users.push(...response.data.body.users)
              }

              // Offset ayarla
              this.usersOffset = response.data.body.offset + response.data.body.limit

              if (this.usersOffset >= response.data.body.totalCount) {
                this.usersAllLoaded = true
              }
            } catch (e) {
              throwDefaultError(this.$toast)
            }
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => {this.loading = false})
    },
    handleScroll (event) {
      if ((window.innerHeight + window.scrollY + this.scrollOffsetForLoadMore) >= document.body.scrollHeight &&
          !this.usersAllLoaded) {
        this.loadMore()
      }
    },
    loadMore() {
      if (!this.loading) {
        this.getUsers(false)
      }
    },
    setLoading($event) {
      this.loading = $event
    },
    updateSearchCriteria(data) {
      useJwt.updateSearchCriteria(data)
    }
  }
}
</script>

<style lang="scss">
/* Set the theme color of the component */
$themeColor: #F9447A;

/* import theme style */
@import '~vue-slider-component/lib/theme/default.scss';
</style>

<style lang="scss" scoped>
.filter-icon {
  display: flex;
  justify-content: end;
  align-items: center;
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  cursor: pointer;
  margin-bottom: 1rem;
}
.enter { transform: translateX(100%) }
.enter-to { transform: translateX(0) }
.slide-enter-active { position: absolute }

.leave { transform: translateX(0) }
.leave-to { transform: translateX(-100%) }

.slide-enter-active,
.slide-leave-active { transition: all 750ms ease-in-out }
</style>
